import React, { useEffect } from 'react';
import { DisplayProcessTime } from '../Transaction/helper';

// dayjs
import dayjs from 'dayjs';

export const generateChecklistItem = (formikData, dataOrder, index) => {
    // Bagian utama checklist
    let checklistText = `
--------------------------------
Checklist ke-${index + 1}/${formikData?.order_details.length}

Nama : ${formikData?.customer?.name}
Tanggal Masuk : ${dayjs(formikData?.createdAt).format('DD-MMM-YYYY')}

Treatment : ${dataOrder?.treatment?.name} - ${DisplayProcessTime(dataOrder?.process_time)}
Tanggal Selesai : ${dayjs(dataOrder?.due_date).format('DD-MMM-YYYY')}

Item ke-${index + 1}/${formikData?.order_details.length}
${dataOrder?.item?.brand} ${dataOrder?.item?.name}
Warna : ${dataOrder?.item?.color}

Catatan : 
${dataOrder?.description || '-'}

Teknisi: ____________________
`;

    return checklistText;
}

export const generateSlip = (formikData, dataOrder, index) => {
    return `
---------quality check---------
Customer: ${formikData?.customer?.name}
Item: ${dataOrder?.item?.brand} - ${dataOrder?.item?.name}
Treatment: ${dataOrder?.treatment?.name}

Teknisi: ____________________
Status: [ ]Pass  [ ]Need Review
Tgl QC: __/__/__ Paraf: ______
--------------------------------
    `
}

export const PrintChecklist = async (formikData) => {
    try {
        const options = {
            filters: [{ name: 'RPP02' }], // Sesuaikan dengan nama printer Anda
            optionalServices: ['e7810a71-73ae-499d-8c15-faa9aef0c3f2'], // Ganti dengan UUID layanan Bluetooth printer Anda
        };

        const device = await navigator.bluetooth.requestDevice(options);
        const server = await device.gatt.connect();
        const service = await server.getPrimaryService('e7810a71-73ae-499d-8c15-faa9aef0c3f2'); // Ganti dengan UUID layanan Bluetooth printer Anda
        const characteristic = await service.getCharacteristic('bef8d6c9-9c21-4c9e-b632-bd58c1009f9f'); // Ganti dengan UUID karakteristik Bluetooth printer Anda


        if (characteristic === null) {
            console.error('Bluetooth characteristic is null');
            return false;
        }

        const encoder = new TextEncoder();

        const clearData = async () => {
            await characteristic.writeValue(encoder.encode('')); // Mengirim nilai kosong ke printer untuk membersihkan data sebelumnya
        };

        const printData = async (data) => {
            await clearData(); // Membersihkan data sebelumnya sebelum mencetak
            await characteristic.writeValue(encoder.encode(data)); // Mengirim data baru ke printer
        };


        const generateInvoice = async (dataOrder) => {
            for (let i = 0; i < dataOrder?.order_details.length; i++) {
                const checklistItem = generateChecklistItem(dataOrder, dataOrder?.order_details[i], i);
                await printData(checklistItem);
                const slip = generateSlip(dataOrder, dataOrder?.order_details[i], i);
                await printData(slip);
            }
        }

        await generateInvoice(formikData);

        return true;


    } catch (error) {
        console.error(error);
        return false;
    }
}